import React, { useState, useEffect } from 'react';
import Editor from '@monaco-editor/react';

interface CodeEditorProps {
  code: string;
  onSQLResponse: (sqlCode: string, result: any) => void; // Callback to handle SQL result
  onSaveCode: (code: string) => void; // New prop to handle saving code
}

const CodeEditor: React.FC<CodeEditorProps> = ({ code, onSQLResponse, onSaveCode }) => {
  const [currentSqlCode, setCurrentSqlCode] = useState(code); // Local state for the code editor

  // Sync currentSqlCode with the code prop when it changes
  useEffect(() => {
    setCurrentSqlCode(code);
  }, [code]);

  // Function to handle query execution
  const ExecuteSqlQuery = () => {
    console.log('Executing SQL query with the following code:');
    console.log(currentSqlCode);

    fetch('http://localhost:8000/run-query', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ query: currentSqlCode }), // Send the current code as the query
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.error('SQL Execution Error:', data.error);
        } else {
          console.log('SQL Query Result:', data);
          // Pass the executed SQL query and result back to the parent component (App)
          onSQLResponse(currentSqlCode, data); // Send both the query and the result to the parent
        }
      })
      .catch((error) => {
        console.error('Error executing SQL query:', error);
      });
  };

  // Function to handle saving the code
  const handleSaveCode = () => {
    onSaveCode(currentSqlCode);
  };

  return (
    <div
      style={{
        height: '300px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '10px',
      }}
    >
      {/* Editor component from monaco-editor */}
      <Editor
        height="80%"
        defaultLanguage="sql" // Set the language to SQL for syntax highlighting
        value={currentSqlCode} // Bind the current SQL code to the editor
        onChange={(value) => setCurrentSqlCode(value || '')} // Update the local state when the editor content changes
      />
      {/* Run and Save buttons */}
      <div style={{ marginTop: '10px' }}>
        <button
          onClick={ExecuteSqlQuery}
          style={{
            padding: '10px 20px',
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          Run
        </button>
        <button
          onClick={handleSaveCode}
          style={{
            marginLeft: '10px',
            padding: '10px 20px',
            backgroundColor: '#28a745',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default CodeEditor;
