// ChatBot.tsx
import React, { useState } from 'react';
import axios from 'axios';
import { format } from 'sql-formatter';

// Global string variable for the input prompt template
const input_prompt_template: string = `
Answer the following input question and answer in a JSON structure based on the requirements below:
{
code_explanation: *If the question is related to writing SQL, return a string that describes what the SQL Code does, otherwise set as null.*
sql_code: *If the question is related to writing SQL, return the PostgreSQL code that addresses the question, otherwise set as null.*
non_sql_response: *if the question is not related to writing SQL, return the entire response here.*
}
REMEMBER THAT WE ARE USING PostgreSQL. DO NOT RETURN A GREETING OR INTRODUCTION. ONLY RETURN THE JSON AS THE OUTPUT.
{input_question}
`;

interface Message {
  sender: 'user' | 'bot';
  text: string;
}

interface ChatBotProps {
  onSQLResponse: (sqlCode: string) => void;
  savedCodeArray: string[]; // Access saved code
}

const ChatBot: React.FC<ChatBotProps> = ({ onSQLResponse, savedCodeArray }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [userInput, setUserInput] = useState('');
  const [loading, setLoading] = useState(false);

  // Function to send user message and fetch bot response
  const handleSend = async () => {
    if (!userInput.trim()) return;

    // Get the most recent code snippet from savedCodeArray
    const sql_context = savedCodeArray.length > 0 ? savedCodeArray[savedCodeArray.length - 1] : '';

    // Format the user input with the input_prompt_template
    let formattedInput = input_prompt_template.replace('{input_question}', userInput);

    // If there is a recent saved code snippet, include it in the prompt
    if (sql_context) {
      formattedInput += `\n\nAlso, if the question is SQL related, consider the following context:\n${sql_context}`;
    }
    console.log("Looking at formattedInput:", formattedInput)

    // Ensure sender is 'user'
    const newMessages: Message[] = [...messages, { sender: 'user', text: userInput }];
    setMessages(newMessages);
    setUserInput('');
    setLoading(true);

    try {
      // Fetch response from OpenAI using the formatted input
      const responseString = await fetchOpenAIResponse(formattedInput);

      // Parse the JSON string into an object
      const response = JSON.parse(responseString);

      // Handle SQL or non-SQL response
      if (response.sql_code) {
        // Format SQL code using sql-formatter
        const formattedSQL = format(response.sql_code);

        // Update CodeEditor with formatted SQL code
        onSQLResponse(formattedSQL);

        // Update chat with code explanation
        const botMessage: Message = {
          sender: 'bot',
          text: response.code_explanation || 'SQL Code provided.',
        };
        setMessages([...newMessages, botMessage]);
      } else {
        // Update chat with non-SQL response
        const botMessage: Message = {
          sender: 'bot',
          text: response.non_sql_response || 'No relevant information provided.',
        };
        setMessages([...newMessages, botMessage]);
      }
    } catch (error) {
      const errorMessage: Message = { sender: 'bot', text: 'Error getting response from OpenAI.' };
      setMessages([...newMessages, errorMessage]);
    } finally {
      setLoading(false);
    }
  };

  // Function to call OpenAI API and return the response as a JSON string
  const fetchOpenAIResponse = async (userMessage: string): Promise<string> => {
    // Get the API key from the .env file
    const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
    if (!apiKey) {
      console.error('OpenAI API key is missing');
      return JSON.stringify({
        code_explanation: null,
        sql_code: null,
        non_sql_response: 'Error: API key not provided.',
      });
    }

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiKey}`,
    };

    const data = {
      model: 'gpt-4',
      messages: [{ role: 'user', content: userMessage }],
      max_tokens: 300,
    };

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        data,
        { headers }
      );
      return response.data.choices[0].message.content;
    } catch (error) {
      console.error('Error fetching response from OpenAI:', error);
      return JSON.stringify({
        code_explanation: null,
        sql_code: null,
        non_sql_response: "Sorry, I couldn't connect to OpenAI.",
      });
    }
  };

  return (
    <div style={styles.chatContainer}>
      <div style={styles.chatWindow}>
        {messages.map((msg, index) => (
          <div
            key={index}
            style={{
              ...styles.message,
              alignSelf: msg.sender === 'user' ? 'flex-end' : 'flex-start',
              backgroundColor: msg.sender === 'user' ? '#d1e7dd' : '#f8d7da',
            }}
          >
            {msg.text}
          </div>
        ))}
      </div>
      <div style={styles.inputContainer}>
        <input
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSend()}
          placeholder="Type a message..."
          style={styles.input}
          disabled={loading}
        />
        <button
          onClick={handleSend}
          style={styles.sendButton}
          disabled={loading}
        >
          {loading ? '...' : 'Send'}
        </button>
      </div>
    </div>
  );
};

// Define styles using React.CSSProperties type
const styles = {
  chatContainer: {
    width: '800px',
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #ccc',
    borderRadius: '10px',
    overflow: 'hidden',
  },
  chatWindow: {
    flex: 1,
    padding: '10px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  message: {
    maxWidth: '70%',
    padding: '10px',
    borderRadius: '10px',
    marginBottom: '10px',
  },
  inputContainer: {
    display: 'flex',
    borderTop: '1px solid #ccc',
  },
  input: {
    flex: 1,
    padding: '10px',
    border: 'none',
    outline: 'none',
  },
  sendButton: {
    padding: '10px',
    border: 'none',
    backgroundColor: '#007bff',
    color: 'white',
    cursor: 'pointer',
  },
} as const;

export default ChatBot;
